import React from "react";
import { Section, Page, Seo } from "gatsby-theme-portfolio-minimal";

export default function LifePuzzleStorePage() {
  return (
    <>
      <Seo title="인생퍼즐 다운로드 링크" useTitleTemplate={true} noIndex={true} />
      <Page>
        <Section anchor={""} heading={"인생퍼즐 다운로드 링크"}>
          <div>
            AppStore & PlayStore 준비 중
          </div>
        </Section>
      </Page>
    </>
  );
}
